/* Modal Overlay - Background */
.genie-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-in-out, visibility 0.4s;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Show overlay when modal is open */
.genie-modal-overlay.show {
    opacity: 1;
    visibility: visible;
}

/* Genie Modal Content */
.genie-modal-content {
    position: relative;
    max-width: 90%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    transform-origin: 50% 100%;
}

/* Genie Effect: Appearing */
@keyframes genie-in {
    0% {
        transform: scaleY(0.05) scaleX(0.3) translateY(100%);
        opacity: 0;
    }

    40% {
        transform: scaleY(1.2) scaleX(0.8) translateY(-10%);
        opacity: 1;
    }

    70% {
        transform: scaleY(0.9) scaleX(1.05) translateY(5%);
    }

    100% {
        transform: scaleY(1) scaleX(1) translateY(0);
    }
}

/* Genie Effect: Disappearing */
@keyframes genie-out {
    0% {
        transform: scaleY(1) scaleX(1) translateY(0);
        opacity: 1;
    }

    30% {
        transform: scaleY(1.2) scaleX(0.8) translateY(-10%);
    }

    60% {
        transform: scaleY(0.05) scaleX(0.3) translateY(100%);
        opacity: 0;
    }

    100% {
        transform: scaleY(0) scaleX(0) translateY(100%);
        opacity: 0;
    }
}

/* Apply Animations */
.genie-in {
    animation: genie-in 0.9s ease-in-out forwards;
}

.genie-out {
    animation: genie-out 0.8s ease-in-out forwards;
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
}